import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import JobSearch from "@sourceflow-uk/job-search";
import imageMetaData from "../../.sourceflow/image_metadata.json";
import { Container, Row, Col } from "reactstrap";
import Link from "next/link";
import styles from "./styles.module.scss";
import Arrow from "/public/site-assets/svg/arrow.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, useTransform, useScroll } from "framer-motion";
import md5 from "md5";
import Helmet from 'react-helmet'

export default function MainBanner({
  global,
  pathPrefix,
  customClass = "",
  content,
  imageURL1,
  imageURL2,
  imageALT1,
  imageALT2,
  jobSearch = false,
  displayImage1 = true,
  pageAnchor = false,
  editableContent = true,
  locale = "https://www.lorienglobal.com",

  buttonLink,
  buttonTitle,
  buttonNewTab = false,
  returnButtonLink,
  returnButtonTitle,
  post,
  region,
  featured_logo = false,
  button_1_class = "fourthBtn"
}) {
  const variants_1 = {
    hidden: { opacity: 0.005, y: 40 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.05 } },
  };
  const variants_2 = {
    hidden: { opacity: 0.005, y: 40 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } },
  };
  return (
    <section
      className={`${styles.root} ${customClass} position-relative ${displayImage1 ? null : "hidden-image-1"
        }`}
    >
      <Helmet>
        {imageURL2 && <link rel="prefetch" href={imageURL2} />}
      </Helmet>
      <Container>
        <Row className="m-0">
          <Col
            lg={6}
            className="contentWrapper d-flex flex-wrap align-items-center position-relative"
          >
            <div>
              {returnButtonLink ?
                <Link href={returnButtonLink}>
                  <a className="return-text text-nowrap arrow-left d-inline-flex align-items-center white mb-4"><strong>{returnButtonTitle}</strong></a>
                </Link>
                : ""}
              {featured_logo ?
                <div className="d-flex gap-4 flex-wrap flex-lg-nowrap">
                  <div>
                    <SourceFlowImage
                      src={
                        post?.image
                          ? post.image
                          : "/site-assets/placeholders/img-placeholder.jpg"
                      }
                      size="450x450"
                      alt={post.title}
                      className="card-img card-logo"
                    />
                  </div>
                  <div>
                    {editableContent ? (
                      <SourceFlowText
                        global={global}
                        path={`${pathPrefix}.content`}
                        type="html"
                      >
                        {content}
                      </SourceFlowText>
                    ) : (
                      content
                    )}
                    {region == "us" ?
                      post?.us_short_description && (
                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: post?.us_short_description }} />
                      ) :
                      post?.short_description && (
                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: post?.short_description }} />
                      )}
                    {buttonLink && (
                      <div className="mt-4">
                        <a href={`${buttonLink}`} className={`${button_1_class} no-arrow m-0`}>
                          {buttonTitle}
                        </a>
                      </div>)
                    }
                    {jobSearch ? (
                      // <motion.div
                      //   initial="hidden"
                      //   animate="visible"
                      //   variants={variants_2}
                      // >
                      <JobSearch
                        options={{
                          searchResults: false,
                          searchFilters: false,
                          submitDestination: `${locale ? locale : ""}/jobs`,
                        }}
                        translations={{
                          "input.placeHolder": "Job Title or Keyword",
                        }}
                      />
                      // </motion.div>
                    ) : null}
                  </div>
                </div>
                :
                <>
                  {editableContent ? (
                    <SourceFlowText
                      global={global}
                      path={`${pathPrefix}.content`}
                      type="html"
                    >
                      {content}
                    </SourceFlowText>
                  ) : (
                    content
                  )}
                  {region == "us" ?
                    post?.us_short_description && (
                      <div className="mt-4" dangerouslySetInnerHTML={{ __html: post?.us_short_description }} />
                    ) :
                    post?.short_description && (
                      <div className="mt-4" dangerouslySetInnerHTML={{ __html: post?.short_description }} />
                    )}
                  {buttonLink && (
                    <div className="mt-4">
                      <Link href={`${buttonLink}`}>
                        <a className={`${button_1_class} no-arrow m-0`} target={buttonNewTab ? '_blank' : '_self'}>
                          {buttonTitle}
                        </a>
                      </Link>
                    </div>)
                  }
                  {jobSearch ? (
                    // <motion.div
                    //   initial="hidden"
                    //   animate="visible"
                    //   variants={variants_2}
                    // >
                    <JobSearch
                      options={{
                        searchResults: false,
                        searchFilters: false,
                        submitDestination: `${locale ? locale : ""}/jobs`,
                      }}
                      translations={{
                        "input.placeHolder": "Job Title or Keyword",
                      }}
                    />
                    // </motion.div>
                  ) : null}
                </>}
            </div>
          </Col>
          {displayImage1 ? (
            <Col lg={6} className="position-relative mainImageWrapper">
              <SourceFlowImage
                src={
                  imageURL1
                    ? imageURL1
                    : "/site-assets/placeholders/img-placeholder.jpg"
                }
                path={`${pathPrefix ? pathPrefix : md5(imageURL1)}.image`}
                imagesMetaData={imageMetaData}
                size="413x448"
                alt={imageALT1}
                className="d-none d-md-block card-img rounded-4"
                loading={"eager"}
              />
            </Col>
          ) : null}
          <div className="imageWrapper position-absolute p-0">
            <SourceFlowImage
              src={
                imageURL2
                  ? imageURL2
                  : "/site-assets/placeholders/img-placeholder.jpg"
              }
              imagesMetaData={imageMetaData}
              path={`${pathPrefix ? pathPrefix : md5(imageURL2)}.v2.image`}
              size="2000x"
              alt={imageALT2}
              className="card-img rounded-0"
              loading={"eager"}
            />
            {/* <img src={`${imageURL2}`} alt={`${imageALT2}`} className="card-img rounded-0"></img> */}
          </div>
        </Row>
        {pageAnchor ? (
          <AnchorLink
            offset="80"
            href={`#${pageAnchor}`}
            className="banner-anchor position-absolute"
            aria-label="Scroll Bottom"
          >
            <Arrow />
          </AnchorLink>
        ) : null}
      </Container>
    </section>
  );
}
