import Helmet from 'react-helmet'
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";
import { useRouter } from 'next/router';

export default function SeoHead({
  site_name = "Lorien",
  page_name = "The global house of tech and transformation recruitment | Lorien",
  description = "We are a market-leading tech, transformation and telecom recruitment company. We deliver scalable solutions, including RPO, MSP and SoW, for companies of all sizes and sectors around the world.",
  canonical_link = "",
  og_image = "https://www.lorienglobal.com/site-assets/images/og-image-v2.jpg",
  og_img_alt = "The global house of tech and transformation recruitment | Lorien",
  en_gb = true,
  en_us,
  robots,
  removeUSHrefLang,
  removeUKHrefLang,
}) {
  const router = useRouter();

  const canonical_url = `https://www.lorienglobal.com${en_us ? "/us" : ""}${
    canonical_link ? "/" + canonical_link : ""
  }`;
  const alternate_url = `https://www.lorienglobal.com${en_gb ? "/us" : ""}${
    canonical_link ? "/" + canonical_link : ""
  }`;

  function getAttributes(id) {
    let obj = metaObject[`${router.asPath}/`]?.filter(item => item.id === id) ?? [];
    return obj[0]?.attributes;
  }
    
  return (
    <>
      <SourceFlowHead
          metaObject={metaObject}
          title={page_name}
          description={description}
          additionalMetaTags={[{
            property: 'og:site_name',
            content: site_name
          }, {
            property: 'title',
            content: page_name
          },
          ]}
          images={[
            {
              url: og_image,
              width: 1200,
              height: 600,
              alt: og_img_alt,
              type: "image/jpeg",
            },
          ]}
          robots={robots}
          canonical={canonical_url}
          locale={en_gb ? "en-gb" : en_us ? "en-us" : null}
          languageAlternates={
            !removeUSHrefLang && !removeUKHrefLang &&
            [
              {
                hrefLang: getAttributes("alternates")?.hreflang ?? (en_gb ? "en-us" : en_us ? "en-gb" : null),
                href: getAttributes("alternates")?.url ?? alternate_url,
              },
            ]
        }
      />

      <Helmet>
        {en_gb && (
          <script type="text/javascript">
            {`
              window.hfAccountId = "98e638e5-3d52-4a88-8e86-cb63ec3e2c6a";
                window.hfDomain = "https://api.herefish.com";
                (function() {
                  var hf = document.createElement('script'); hf.type = 'text/javascript'; hf.async = true;
                  hf.src = window.hfDomain + '/scripts/hf.js';
                  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hf, s);
                })();
            `}
          </script>
        )}
        {en_us && (
          <script type="text/javascript">
            {`
              window.hfAccountId = "0a68c212-6bb7-4323-8277-f075952c6e87";
                window.hfDomain = "https://api.herefish.com";
                (function() {
                  var hf = document.createElement('script'); hf.type = 'text/javascript'; hf.async = true;
                  hf.src = window.hfDomain + '/scripts/hf.js';
                  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hf, s);
                })();
            `}
          </script>
        )}
        {en_us && (
          /* WebSights - ZoomInfo */
          <script type="text/javascript">
            {`
              window[(function(_RYs,_kB){var _tS='';for(var _d4=0;_d4<_RYs.length;_d4++){var _Ch=_RYs[_d4].charCodeAt();_Ch!=_d4;_Ch-=_kB;_Ch+=61;_tS==_tS;_kB>4;_Ch%=94;_Ch+=33;_tS+=String.fromCharCode(_Ch)}return _tS})(atob('bl1kKCV+eXcqX3kv'), 20)] = '4363e801b11680116016';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_I0D,_Q9){var _So='';for(var _wg=0;_wg<_I0D.length;_wg++){var _Dq=_I0D[_wg].charCodeAt();_Dq-=_Q9;_Dq!=_wg;_Q9>4;_Dq+=61;_So==_So;_Dq%=94;_Dq+=33;_So+=String.fromCharCode(_Dq)}return _So})(atob('Kzc3MzZbUFAtNk89LE42JjUsMzc2TyYyMFA9LE43JCpPLTY='), 33)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });
            `}
          </script>
        )}
      </Helmet>

    </>
  );
}

SeoHead.defaultProps = {
  robots: null,
};
